import React from "react";
import styled from "styled-components";
import { PageProps, graphql } from "gatsby";
import { BlocksContent, Breadcrumb, Link } from "@global";
import { Query } from "@graphql-types";
import Hero from "@shared/hero";
import {
  Container,
  HeroH2,
  GridContainer,
  GridColumnSpan,
  H3,
} from "@util/standard";
import Layout from "@shared/layout";
import SEO from "@shared/seo";
import { BreadcrumbPageContext } from "@util/types";
import {
  colors,
  fontWeights,
  fontSizes,
  IPAD_BREAKPOINT,
} from "@util/constants";
import { navigate } from "gatsby";

const ContentContainer = styled(Container)`
  width: 80%;
  flex-direction: column;
  margin: 5% auto;
  .cta--btn {
    &:hover {
      opacity: 1;
    }
    p {
      font-size: ${fontSizes.p.alternate}px;

      letter-spacing: 0.08em;
      border: 1.5px solid ${colors.green};
      border-radius: 36px;
      font-weight: ${fontWeights.bold};
      padding: 14px 40px;
      width: fit-content;
      margin: 15px auto 0;
      &:hover {
        background-color: ${colors.green};
        color: ${colors.white};
        text-decoration: none;
      }
    }
  }
  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    font-size: ${fontSizes.button.mobile}px;
    padding: 11px 25px;
  }
`;

interface Props extends PageProps {
  data: Query;
  pageContext: BreadcrumbPageContext;
}

const CareerPage = ({ pageContext, data }: Props) => {
  const sanityCareerPage = data.allSanityCareerPage.nodes[0];

  if (sanityCareerPage == null) {
    return null;
  }

  return (
    <>
      <SEO seoData={sanityCareerPage.seo} />
      <Layout>
        <Hero height="650px" sanityHero={sanityCareerPage?.hero} />
        <Breadcrumb pageContext={pageContext} />
        <ContentContainer>
          <GridContainer
            repeat={6}
            mobileRepeat={1}
            tabletRepeat={1}
            columnGap={20}
          >
            <GridColumnSpan gridColumnWidth={6} paddingBottom={20}>
              <a onClick={() => navigate(-1)}>&lt; Back</a>
            </GridColumnSpan>
            <GridColumnSpan gridColumnWidth={2}>
              {/* <HeroH2 color={colors.green} overrideFont="Poppins"> */}
              <H3 >{sanityCareerPage?.careerTitle}</H3>
            </GridColumnSpan>
            <GridColumnSpan gridColumnWidth={4}>
              <BlocksContent blocks={sanityCareerPage.careerInfo?._rawBlocks} />
            </GridColumnSpan>
            <GridColumnSpan gridColumnWidth={2}>
              <Link
                className="cta--btn"
                linktext={sanityCareerPage?.careerCta?.linktext}
                url={sanityCareerPage?.careerCta?.url}
                internallink={sanityCareerPage?.careerCta?.internallink}
                newwindow={sanityCareerPage?.careerCta?.newwindow}
              />
            </GridColumnSpan>
          </GridContainer>
        </ContentContainer>
      </Layout>
    </>
  );
};

export default CareerPage;

export const query = graphql`
  query allCareerQuery($iban: String) {
    allSanityCareerPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        careerCta {
          _key
          internallink
          _type
          linktext
          newwindow
          url
        }
        careerInfo {
          _key
          blocks {
            style
            _type
            _rawChildren
            _key
          }
          _type
          _rawBlocks
        }
        careerTitle
      }
    }
  }
`;
